import React from 'react'
import {graphql} from 'gatsby'
import {GatsbyImage} from 'gatsby-plugin-image'
import {Box,Flex,Heading,Text} from '@chakra-ui/react'
import MySEO from '../components/seo'

const Aktivitaet = ({data}) => {

return(
    <div>
        <MySEO
        title="kulturaktivitäten"
        description="Hier finden Sie einen Ausschnitt von Kulturaktivitäten von hasen oder bein"
      />
    <Heading># kulturaktivitäten</Heading>

    <Text variant="bildtext"><b>Kreativ für Kreative. </b>
     Seit 2019 freut sich hasen oder bein, für das Theater Willy Praml aus Frankfurt a.M. den visuellen Auftritt seines Theaterfestivals "An den Ufern der Poesie" im Mittelrheintal visuell gestalten zu dürfen. Hasen oder bein hat hierfür, inspiriert durch einen Fährschein, eine sich einprägende Bildmarke entwickelt.
     Im Rahmen des Theaterfestivals entstanden Flyer, Broschüren, Anzeigen, eine Zeitung sowie Banner. Eine neue Website wird bald freigeschaltet.</Text>
    <Flex>

           <Box width={["100%","100%","49%"]}>
                <GatsbyImage alt="Finetrader Magazin" image= {data.bilder.edges[0].node.childImageSharp.gatsbyImageData} />

           </Box>

       <Box width={["100%","100%","49%"]}>
            <GatsbyImage alt="Finetrader Magazin" image= {data.bilder.edges[1].node.childImageSharp.gatsbyImageData} />
       </Box>
      </Flex>
       <Flex>
       <Box width={["100%","100%","49%"]}>
            <GatsbyImage alt="Finetrader Magazin" image= {data.bilder.edges[2].node.childImageSharp.gatsbyImageData} />

       </Box>

   <Box width={["100%","100%","49%"]}>
        <GatsbyImage alt="Finetrader Magazin" image= {data.bilder.edges[3].node.childImageSharp.gatsbyImageData} />
   </Box>
     </Flex>
    </div>
)}


export const AktiQuery = graphql`
 query {
  bilder:allFile(filter: {absolutePath: {regex: "/_aktivitaet/"}}) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
 }


`

  export default Aktivitaet
